import { yupResolver } from "@hookform/resolvers/yup";
import { LoadingButton } from "@mui/lab";
import {
    Alert,
    Button,
    Stack,
    Typography
} from "@mui/material";
import { CountryCode } from "libphonenumber-js";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { fallbackNameReader, UserAccountGeneral } from "src/models/UserAccountGeneral";
import countryConfig, { CountryConfig } from "src/utils/mrr/phone/countryConfig";
import * as Yup from 'yup';
import {
    RHFSelect,
    RHFTextField
} from "../../components/hook-form";
import FormProvider from "../../components/hook-form/FormProvider";
import Iconify from "../../components/iconify";
import {
    GuestGeneral,
    GuestType
} from "../../models/GuestGeneral";
import { usePatchRegistrationStepMutation, usePutReservationGuestMutation } from "../../redux/rtkQuery/apiSlice";
import { checkAgeValid, convertInputTextToInt, handleIntegerInputChange } from "../../utils/mrr/ageUtils";
import { cloud_postReservationGuest } from "../../utils/mrr/cloudFunctions";
import { extractCountryConfig, validatePhoneNumber } from "../../utils/mrr/phone/phone";
import { EMAIL_REGEX } from "../../utils/mrr/uiConstants";
import { yupCheckPhone } from "../account/userSchemes";
import { StandardRTKQError } from "../error/StandardRTKQError";
import { RegistrationPicklistItem } from "./types";


interface RegistrationMainGuestFormProps {
    stepCompleted: boolean,
    ageRequired: boolean,
    minAge: number,
    mainGuest: GuestGeneral | null,
    reservationName: string,
    sessionUser: UserAccountGeneral,
    callbackCancel: VoidFunction,
    callbackChangeHost: VoidFunction,
    callbackContinue: VoidFunction,
};

interface FormInputProps {
    name: string;
    age: string;
    email: string;
    phone: string;
    address: string;
    city: string;
    state: string;
    zipCode: string;
    country: string;
}

export function RegistrationMainGuestForm({
    stepCompleted,
    ageRequired,
    minAge,
    mainGuest,
    reservationName,
    sessionUser,
    callbackCancel,
    callbackChangeHost,
    callbackContinue }: RegistrationMainGuestFormProps
) {
    const [globalError, setGlobalError] = useState<string | null>(null);
    // const handleExpiredSession = useExpiredSessionErrorToNavigate();

    const resolverBase = useMemo(() => {
        return Yup.object().shape({
            name: Yup.string().required('Name is required'),
            email: Yup.string().required('Email is required').matches(EMAIL_REGEX, 'Please provide a valid email.'),
            phone: Yup.string().required('Phone is required').test('phone', 'Please provide a valid phone number.', yupCheckPhone),
            age:
                ageRequired
                    ? Yup.string().required('Host age is required.').test(
                        'age',
                        //NOTE: The full "Host must be at least..." message is shown as a static info tip.
                        'Must be at least ' + minAge,
                        (value) => {
                            const age = parseInt(value || '', 10);
                            return checkAgeValid(age, minAge);
                        })
                    : Yup.string().test(
                        'age',
                        'Age is invalid.',
                        (value) => {
                            const age = parseInt(value || '', 10);
                            return checkAgeValid(age);
                        }),
            address: Yup.string().required('Please provide an address.'),
            city: Yup.string().required('Please provide a city.'),
            state: Yup.string().nullable('Please provide a state/province.'),
            zipCode: Yup.string().required('Please provide a Zip/code.'),
            country: Yup.string().nullable()
        });
    }, [ageRequired, minAge]);

    const firstName = mainGuest?.first_name || '';
    const lastName = mainGuest?.last_name || '';

    const formDefaults: any = mainGuest
        ? {
            name: (firstName || lastName) ? (firstName + ' ' + lastName) : '',
            email: mainGuest.email || '',
            phone: mainGuest.phone || '',
            age: mainGuest.age !== undefined ? String(mainGuest.age) : '',
            address: mainGuest.address && mainGuest.address.street ? mainGuest.address.street : '',
            city: mainGuest.address && mainGuest.address.city ? mainGuest.address.city : '',
            state: mainGuest.address && mainGuest.address.state ? mainGuest.address.state : '',
            zipCode: mainGuest.address && mainGuest.address.postal_code ? mainGuest.address.postal_code : '',
            country: mainGuest.address && mainGuest.address.country ? mainGuest.address.country : 'US'
        }
        : {
            name: '',
            email: '',
            phone: '',
            age: '',
            address: '',
            city: '',
            state: '',
            zipCode: '',
            country: 'US'
        };

    const methods = useForm<FormInputProps>({
        defaultValues: formDefaults,
        resolver: yupResolver(resolverBase),
        mode: 'all',
    });

    const {
        handleSubmit,
        formState: {
            isDirty,
            isSubmitting,
            isValid,
            errors
        },
        trigger,
        setError,
        getFieldState,
        setValue,
        watch,
    } = methods;

    const values = watch();
    const [stateOptions, setStateOptions] = useState<CountryConfig>();

    // drives the PROVINCE/STATE fields by watching changes to country
    useEffect(() => {
        if (values.country) {
            const options = countryConfig.find((country) => (country.code === values.country) && country.divisions);
            setStateOptions(options);
            if (!options) {
                setValue('state', '')
            }
            else {
                // when we receive a code-based division, e.g. "HI", convert it to the
                // full name for the drop-down control, e.g. "Hawaii"

                const foundByCode = options.divisions
                                    ? options.divisions.find((optionObj) => optionObj.code === values.state)
                                    : '';

                if (foundByCode) {
                    setValue('state', foundByCode.name);
                }
            }
        } else if (stateOptions) {
            setValue('state', '')
            setStateOptions(undefined);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [values.country]);

    const [
        sendPutReservationGuest,
        {
            isLoading: putGuestIsLoading,
            isError: putGuestIsError,
            error: putGuestError,
            reset: resetPutGuestMutation
        },
    ] = usePutReservationGuestMutation();

    const [
        sendPatchRegistrationStep,
        {
            isLoading: patchStepIsLoading,
            isError: patchStepIsError,
            error: patchStepError,
            reset: resetPatchStepMutation
        },
    ] = usePatchRegistrationStepMutation();

    const completeMainGuestStep = useCallback(async () => {
        const sfPayload = {
            reservationName: reservationName,
            registrationStep: RegistrationPicklistItem.VERIFY_HOST
        };

        await sendPatchRegistrationStep(sfPayload)
            .then((data: any) => {
                if (data.error) {
                    //TODO: Double check this step. Errors were already handled on this page before a refactor.
                    console.error(data.error.message);
                    return;
                }
                callbackContinue();
            })
            .catch((putError: any) => {
                console.error('error on update main guest step', putError);
            });

        //TODO
        // sendEvent(new GAEventGuest(
        //     sfPayload.type,
        //     sfPayload.is_minor,
        //     sfPayload.first_name,
        //     sfPayload.last_name,
        //     true,
        //     sfPayload.relation,
        //     reservation_name,
        // ))
    }, [callbackContinue, reservationName, sendPatchRegistrationStep]);

    // const { sendEvent } = useAnalyticsContext()

    const onSubmit = handleSubmit(async ({
        name,
        email,
        phone,
        age,
        address,
        city,
        state,
        zipCode,
        country
    }) => {
        let manual_formClean = false;

        if (!mainGuest) {
            console.warn('main guest missing on submit');
        }
        else {
            manual_formClean =
                mainGuest.age === parseInt(age, 10)
                && !!mainGuest.email
                && mainGuest.email.trim().toLowerCase() === email.trim().toLowerCase()
                && mainGuest.phone === phone
                && mainGuest.address.street === address
                && mainGuest.address.city === city
                && mainGuest.address.state === state
                && mainGuest.address.postal_code === zipCode
                && mainGuest.address.country === country
        }

        const fallbackName = fallbackNameReader(name.trim());

        if (!manual_formClean) {
            const sfPayload = {
                hostMode: true,
                reservationName: reservationName,
                first_name: firstName ? firstName : fallbackName.first,
                last_name: lastName ? lastName : fallbackName.last,
                email: email.trim().toLowerCase(),
                phone,
                age,
                mailing_address: {
                    street: address,
                    postal_code: zipCode,
                    city,
                    state,
                    country
                },
                type: GuestType.Host
            };

            if (phone) {
                const extractedCountryConfig = extractCountryConfig(phone);
                let sfPhone = '';
                const phoneResult = validatePhoneNumber(phone || '', extractedCountryConfig.code as CountryCode);
                if (phoneResult.success) {
                    sfPhone = phoneResult.parsedPhone.formatInternational();
                }
                if (sfPhone) {
                    sfPayload.phone = sfPhone;
                }
            }

            // UPDATE EXISTING
            await sendPutReservationGuest(sfPayload)
                .then(async (data: any) => {
                    if (data.error) {
                        setGlobalError(data.error.message);
                        return;
                    }
                    await completeMainGuestStep();
                })
                .catch((putError: any) => {
                    console.error('error on update main guest', putError);
                });

            return;
        }

        if (stepCompleted) {
            callbackContinue();
            return;
        }

        await completeMainGuestStep();
    });

    const showStandardError = putGuestIsError && !globalError;
    const showGlobalError = globalError;

    const formLoading = isSubmitting || putGuestIsLoading;

    function checkEmailValid() {
        return EMAIL_REGEX.test(values.email);
    }

    function checkPhoneValid() {
        const result = validatePhoneNumber(values.phone, values.country as CountryCode || 'US');
        return result.success;
    }

    const readonlyName = !!formDefaults.name;
    const readonlyEmail = !!formDefaults.email && checkEmailValid();
    const readonlyPhone = !!formDefaults.phone && checkPhoneValid();

    return (
        <FormProvider name="Main Guest Verify Form" id={`Main Guest Verify Form: ${reservationName}`} methods={methods} onSubmit={onSubmit}>
            <Stack spacing={2} mt={1}>
                {showStandardError &&
                    <StandardRTKQError
                        error={putGuestError}
                        endpoint={cloud_postReservationGuest}
                        mutationCall
                    />}
                {showGlobalError &&
                    <Alert
                        severity="error">
                        {globalError}
                    </Alert>}
                <RHFTextField
                    fullWidth
                    disabled={readonlyName}
                    aria-readonly={readonlyName}
                    aria-required
                    name="name"
                    type="text"
                    label='Name'
                    focused={false}
                    sx={{ ...(readonlyName && { pointerEvents: 'none' }) }}
                />
                <RHFTextField
                    fullWidth
                    disabled={readonlyEmail}
                    aria-readonly={readonlyEmail}
                    aria-required
                    name="email"
                    type="email"
                    label='Email'
                    sx={{ ...(readonlyEmail && { pointerEvents: 'none' }) }}
                />
                <RHFTextField
                    fullWidth
                    disabled={readonlyPhone}
                    aria-readonly={readonlyPhone}
                    aria-required
                    name="phone"
                    type="phone"
                    label='Phone'
                    sx={{ ...(readonlyPhone && { pointerEvents: 'none' }) }}
                />
                <RHFTextField
                    fullWidth
                    aria-required
                    name="age"
                    label='Age'
                    onChange={(e) => {
                        const parsedValue = handleIntegerInputChange(e);
                        setValue('age', convertInputTextToInt(parsedValue));
                        trigger('age');
                    }}
                />
                {ageRequired &&
                    <Stack
                        alignItems='flex-start'
                        direction='row'
                        spacing={.5}>
                        <Iconify
                            icon='flat-color-icons:info'
                            width={16}
                        />
                        <Typography
                            fontSize={12}
                            variant="caption"
                            color='text.secondary'>
                            {'Host must be at least ' + minAge + ' years old at date of check-in.'}
                        </Typography>
                    </Stack>
                }

                <RHFTextField type='text' name="address" label="Address" />
                <RHFTextField type='text' name="city" label="City" />
                {stateOptions && stateOptions.divisions &&
                  <RHFSelect native name="state" label={stateOptions.divisionLabel}>
                    <option value="" />
                    {stateOptions.divisions.map((optionObj) => (
                      <option key={optionObj.name} value={optionObj.name}>
                        {optionObj.name}
                      </option>
                    ))}
                  </RHFSelect>
                }
                <RHFTextField type='text' name="zipCode" label="Zip/Code" />
                <RHFSelect
                  native
                  name="country"
                  label="Country"
                  placeholder="Country"
                  onInput={(event: any) => {
                    setValue('country', event.target.value)
                  }}
                >
                  <option value="" />
                  {countryConfig.map((country) => (
                    <option key={country.code} value={country.code}>
                      {country.name}
                    </option>
                  ))}
                </RHFSelect>
            </Stack>

            {/* Footer buttons */}
            <Stack direction='row' justifyContent='space-between' width='100%' mt={4}>
                <Button
                    disabled={isSubmitting}
                    onClick={callbackCancel}
                >
                    Back
                </Button>
                <Stack direction='row' spacing={1}>
                    <Button
                        color="secondary"
                        disabled={isSubmitting}
                        variant="contained"
                        onClick={callbackChangeHost}
                    >
                        Change Host
                    </Button>
                    <LoadingButton
                        type="submit"
                        loading={formLoading}
                        disabled={!isValid}
                        variant="contained"
                    >
                        Continue
                    </LoadingButton>
                </Stack>
            </Stack>
        </FormProvider>
    );
}
