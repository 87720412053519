import { isToday } from 'date-fns';
import { sum } from 'lodash';
import { useMemo } from 'react';
import { brandConfig } from 'src/config';
import { useGetReservationRevenueQuery } from '../redux/rtkQuery/apiSlice';
import { ReservationGeneral } from './ReservationGeneral';
import { BrandConfig } from 'src/config/brandConfig';

const _emptyReservationUIObject = {
  Display_Travel_Insurance_Pitch: false,
  Display_Extend_Stay_Button: false,
  Disable_Extend_Stay_Button: false,
  Display_Request_Late_Check_Out_Button: false,
  Disable_Request_Late_Check_Out_Button: false,
  Display_Payment_Info: false,
  Add_Service: false,
  Edit_Flight_Info: false,
  Display_Add_Check_In_Request_Linked: false,
  Display_Add_Check_In_Request: false,
  Disable_Add_Check_In_Request: false,
  Display_Add_Guests: false,
  Allow_Update_Guest: false,
  Do_Not_Display_Support_Section: false,
  Display_Access_Codes: false,
  Display_Balance_Due_Alert: false,
  Display_Balance_Due_Warning: false,
  Display_Financials: false,
  Balance_Due_On_Checkout: false,
};

export const buildReservationUIObject = (
  reservation?: ReservationGeneral,
  pendingPaymentAmount?: number,
  brand?: BrandConfig
) => {
  if (!reservation || typeof pendingPaymentAmount !== 'number') {
    return _emptyReservationUIObject;
  }
  const {
    extend_stay_allowed,
    external_revenue,
    has_late_check_out,
    has_travel_insurance,
    is_current_booking,
    is_future_booking,
    is_host,
    is_hybrid_child,
    is_hybrid_parent,
    is_inherited,
    is_past_booking,
    is_timeshare,
    late_check_out_allowed,
    late_check_out_amount,
    travel_insurance_eligible,
    is_balance_past_due,
    balance_due,
    hide_financials,
    check_out_ISO,
  } = reservation;

  const balance_due_on_checkout = isToday(check_out_ISO);
  return {
    Display_Travel_Insurance_Pitch:
      brandConfig.travelInsuranceEnabled &&
      is_host &&
      !is_hybrid_child &&
      !has_travel_insurance &&
      travel_insurance_eligible &&
      !is_inherited,
    Display_Extend_Stay_Button: is_host && !is_past_booking && !is_hybrid_parent && !is_timeshare,
    Disable_Extend_Stay_Button: !extend_stay_allowed,
    Display_Request_Late_Check_Out_Button:
      is_host && !is_past_booking && !is_timeshare && !is_hybrid_parent && !!late_check_out_amount,
    Disable_Request_Late_Check_Out_Button: !late_check_out_allowed || has_late_check_out,
    Display_Payment_Info: is_host && !is_hybrid_child,
    Add_Service: is_host && !is_past_booking && !is_timeshare,
    Edit_Flight_Info: is_host,
    Display_Add_Check_In_Request_Linked:
      is_host &&
      is_future_booking &&
      !is_timeshare &&
      is_hybrid_parent &&
      brand?.allowCheckInRequests,
    Display_Add_Check_In_Request:
      is_host &&
      is_future_booking &&
      !is_timeshare &&
      !is_hybrid_parent &&
      brand?.allowCheckInRequests,
    Disable_Add_Check_In_Request: !brand?.allowCheckInRequests || false,
    Display_Add_Guests: is_host && !is_past_booking,
    Allow_Update_Guest: is_host && !is_past_booking,
    Do_Not_Display_Support_Section: is_past_booking,
    Display_Access_Codes:
      is_current_booking && !is_timeshare && !(!external_revenue && balance_due > 0),
    Display_Balance_Due_Alert:
      (is_balance_past_due &&
        is_host &&
        !external_revenue &&
        balance_due - pendingPaymentAmount > 0) ||
      (balance_due_on_checkout &&
        is_host &&
        !external_revenue &&
        balance_due - pendingPaymentAmount > 0),
    Display_Balance_Due_Warning:
      balance_due > 0 &&
      !is_balance_past_due &&
      is_host &&
      !external_revenue &&
      balance_due - pendingPaymentAmount > 0,
    Display_Financials: !hide_financials,
  };
};

export default function useReservationUIConfig(reservation?: ReservationGeneral) {
  const {
    data: revenueData,
    isSuccess: revenueSuccess,
    isFetching: revenueFetching,
    // isError: revenueError,
    // error: revenueErrorObject
  } = useGetReservationRevenueQuery(
    {
      reservationName: reservation?.name,
    },
    { skip: !reservation || !reservation.is_host }
  );

  const pendingPayments = !revenueSuccess
    ? []
    : revenueData.filter((payment) => payment.status === 'Pending');
  const pendingPaymentAmount = sum(pendingPayments.map((payment) => payment.amount));

  const uiData = useMemo(
    () => buildReservationUIObject(reservation, pendingPaymentAmount, brandConfig),
    [reservation, pendingPaymentAmount]
  );
  if (revenueFetching) {
    return _emptyReservationUIObject;
  }
  return uiData;
}
