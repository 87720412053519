import { yupResolver } from "@hookform/resolvers/yup";
import { LoadingButton } from "@mui/lab";
import {
    Alert,
    Button,
    Divider,
    Stack,
    Typography
} from "@mui/material";
import { CountryCode } from "libphonenumber-js";
import { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import {
    RHFAutocomplete,
    RHFTextField
} from "src/components/hook-form";
import * as Yup from 'yup';
import FormProvider from "../../components/hook-form/FormProvider";
import {
    GuestGeneral,
    GuestRelationOptions,
    GuestType
} from "../../models/GuestGeneral";
import { usePostReservationGuestMutation, usePutReservationGuestMutation } from "../../redux/rtkQuery/apiSlice";
import { checkAgeValid, convertInputTextToInt, handleIntegerInputChange, MINOR_AGE } from "../../utils/mrr/ageUtils";
import { cloud_postReservationGuest, cloud_putReservationGuest } from "../../utils/mrr/cloudFunctions";
import { extractCountryConfig, validatePhoneNumber } from "../../utils/mrr/phone/phone";
import { EMAIL_REGEX } from "../../utils/mrr/uiConstants";
import { yupCheckPhone } from "../account/userSchemes";
import { StandardRTKQError } from "../error/StandardRTKQError";


interface RegistrationUpsertGuestFormProps {
    reservationName: string,
    guestToEdit: GuestGeneral | null,
    callbackCancel: VoidFunction,
    callbackSubmit: VoidFunction,
};

interface FormInputProps {
    first_name: string,
    last_name: string,
    age: string,
    email: string,
    phone: string,
    relation: string;
}


export function RegistrationUpsertGuestForm({
    reservationName,
    guestToEdit,
    callbackCancel,
    callbackSubmit }: RegistrationUpsertGuestFormProps
) {
    const [globalError, setGlobalError] = useState<string | null>(null);
    // const handleExpiredSession = useExpiredSessionErrorToNavigate();

    const resolver = useMemo(() => {
        return Yup.object().shape({
            first_name: Yup.string().required('First name is required.'),
            last_name: Yup.string().required('Last name is required.'),
            age: Yup.string().required('Please provide age at date of check-in.').test('age', 'Please provide age at date of check-in.', (value) => {
                const age = parseInt(value || '', 10);
                return checkAgeValid(age);
            }),
            email: Yup.string().nullable().notRequired().test('email', 'Please enter a valid email.', (value) => {
                if (!value) {
                    // empty email allowed
                    return true;
                }

                return EMAIL_REGEX.test(value);
            }),

            //NOTE: This error is a fallback, not shown while using 'yupCheckPhone()'.
            phone: Yup.string().nullable().notRequired().test('phone', 'Please provide a valid phone number.', (value: any, context: Yup.TestContext) => {
                if (!value) {
                    // empty phone allowed
                    return true;
                }

                return yupCheckPhone(value, context);
            }),
            relation: Yup.string().oneOf(GuestRelationOptions, 'Please select from the list, or leave empty.').nullable().notRequired()
        });
    }, []);

    const formDefaults = {
        first_name: guestToEdit?.first_name || '',
        last_name: guestToEdit?.last_name || '',
        age: (guestToEdit && guestToEdit.age !== undefined) ? String(guestToEdit.age) : '',
        email: guestToEdit?.email || '',
        phone: guestToEdit?.phone || '',
        //TODO: There is a react-hook-forms issue here, controlled state change.
        //      Using anything other than undefined leads to the form being 'stuck'. It treats
        //      the field as required. We need a better solution, but the console warning is
        //      better than the form being stuck; no functional issues.
        relation: (guestToEdit && guestToEdit.relation !== undefined) ? guestToEdit.relation : undefined
    };

    const methods = useForm<FormInputProps>({
        defaultValues: formDefaults,
        resolver: yupResolver(resolver),
        mode: 'all',
    });

    const {
        handleSubmit,
        formState: {
            isDirty,
            isSubmitting,
            isValid,
            dirtyFields,
            errors
        },
        trigger,
        setError,
        getFieldState,
        setValue,
        watch,
    } = methods;

    const [
        sendPostReservationGuest,
        {
            isLoading: postGuestIsLoading,
            isError: postGuestIsError,
            error: postGuestError,
            reset: resetPostGuestMutation
        },
    ] = usePostReservationGuestMutation();

    const [
        sendPutReservationGuest,
        {
            isLoading: putGuestIsLoading,
            isError: putGuestIsError,
            error: putGuestError,
            reset: resetPutGuestMutation
        },
    ] = usePutReservationGuestMutation();

    // const { sendEvent } = useAnalyticsContext()

    const onSubmit = handleSubmit(async ({
        first_name,
        last_name,
        age,
        email,
        phone,
        relation
    }) => {
        const ageNumber = Number(convertInputTextToInt(age));

        const sfPayload = {
            reservationName,
            type: guestToEdit?.type || GuestType.Guest,
            first_name,
            last_name,
            is_minor: ageNumber < MINOR_AGE,
            age,
            // Optional Fields
            ...(relation && { relation }),
            ...(email && { email }),
            ...(phone && { phone }),
        };

        if (phone) {
            const countryConfig = extractCountryConfig(phone);
            let sfPhone = '';
            const phoneResult = validatePhoneNumber(phone || '', countryConfig.code as CountryCode);
            if (phoneResult.success) {
                sfPhone = phoneResult.parsedPhone.formatInternational();
            }
            if (sfPhone) {
                sfPayload.phone = sfPhone;
            }
        }

        const usePUT = !!guestToEdit;

        if (usePUT) {
            (sfPayload as any).guestId = guestToEdit?.id;

            // UPDATE EXISTING GUEST
            await sendPutReservationGuest(sfPayload)
                .then((data: any) => {
                    if (data.error) {
                        setGlobalError(data.error.message);
                        return;
                    }
                    callbackSubmit();
                })
                .catch((putError: any) => {
                    console.error('error on update add\'l guest', putError);
                });
        }
        else {
            // CREATE NEW
            await sendPostReservationGuest(sfPayload)
                .then((data: any) => {
                    if (data.error) {
                        setGlobalError(data.error.message);
                        return;
                    }

                    callbackSubmit();
                })
                .catch((postError: any) => {
                    console.error('error on create add\'l guest', postError);
                });
        }

        //TODO
        // sendEvent(new GAEventGuest(
        //     sfPayload.type,
        //     sfPayload.is_minor,
        //     sfPayload.first_name,
        //     sfPayload.last_name,
        //     true,
        //     sfPayload.relation,
        //     reservation_name,
        // ))
    });

    //NOTE: 'Host' is not a valid option, but for legacy reasons it can be set on the guest.
    //      If it is set, it breaks the form (even though it's optional), because it's not
    //      one of the drop-down options.
    //      We poke this at startup, to show Yup instructions on the input in this case.
    useEffect(() => {
        if (guestToEdit && guestToEdit.relation && (guestToEdit.relation as any).toLowerCase() === 'host') {
            trigger('relation');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const showStandardError = (postGuestIsError || putGuestIsError) && !globalError;
    const showGlobalError = globalError;

    const formLoading = isSubmitting || postGuestIsLoading;

    return (
        <FormProvider name="Upsert Guest Form" id={`Upsert Guest Form: ${reservationName}`} methods={methods} onSubmit={onSubmit}>
            <Stack spacing={2}>
                {showStandardError &&
                    (
                        postGuestIsError
                            ? <StandardRTKQError
                                error={postGuestError}
                                endpoint={cloud_postReservationGuest}
                                mutationCall
                            />
                            : <StandardRTKQError
                                error={putGuestError}
                                endpoint={cloud_putReservationGuest}
                                mutationCall
                            />
                    )
                }
                {showGlobalError &&
                    <Alert
                        severity="error">
                        {globalError}
                    </Alert>}
                <RHFTextField
                    fullWidth
                    aria-required
                    name="first_name"
                    label='First Name' />
                <RHFTextField
                    fullWidth
                    aria-required
                    name="last_name"
                    label='Last Name' />
                <RHFTextField
                    fullWidth
                    aria-required
                    name="age"
                    label='Age'
                    onChange={(e) => {
                        const parsedValue = handleIntegerInputChange(e);
                        setValue('age', convertInputTextToInt(parsedValue));
                        trigger('age'); // onChange breaks Yup's as-you-type validation
                    }}
                />
                <Divider />
                <Typography variant='h6' mt={2}>Optional guest information.</Typography>
                <RHFTextField
                    name="email"
                    type="email"
                    label='Email' />
                <RHFTextField
                    name="phone"
                    type="phone"
                    label='Phone' />
                <RHFAutocomplete
                    fullWidth
                    aria-required
                    name='relation'
                    options={GuestRelationOptions}
                    label="Relation" />
            </Stack>

            {/* Footer buttons */}
            <Stack direction='row' justifyContent='space-between' width='100%' mt={4}>
                <Button
                    disabled={isSubmitting}
                    onClick={callbackCancel}
                >
                    Cancel
                </Button>
                <LoadingButton
                    type="submit"
                    loading={formLoading}
                    disabled={!isValid}
                    variant="contained"
                >
                    {(guestToEdit ? 'Update' : 'Add') + ' Guest'}
                </LoadingButton>
            </Stack>
        </FormProvider>
    );
}
