import { yupResolver } from "@hookform/resolvers/yup";
import { LoadingButton } from "@mui/lab";
import {
    Button,
    ButtonGroup,
    Stack
} from "@mui/material";
import { useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import * as Yup from 'yup';
import {
    RHFTextField
} from "../../components/hook-form";
import FormProvider from "../../components/hook-form/FormProvider";
import { ReservationGeneral } from "../../models/ReservationGeneral";
import { usePatchRegistrationStepMutation } from "../../redux/rtkQuery/apiSlice";
import { cloud_patchRegistrationStep } from "../../utils/mrr/cloudFunctions";
import { StandardRTKQError } from "../error/StandardRTKQError";
import { RegistrationPicklistItem } from "./types";

interface RegistrationVehiclesFormProps {
    stepCompleted: boolean,
    reservation: ReservationGeneral,
    callbackCancel: VoidFunction,
    callbackContinue: VoidFunction,
};

interface FormInputProps {
    vehicles: string;
};

const MAX_INFO_LENGTH = 60;

export function RegistrationVehiclesForm({
    stepCompleted,
    reservation,
    callbackCancel,
    callbackContinue }: RegistrationVehiclesFormProps
) {
    const [textInputLocked, setTextInputLocked] = useState<boolean>(false);
    // const handleExpiredSession = useExpiredSessionErrorToNavigate();

    const resolver = useMemo(() => {
        return Yup.object().shape({
            vehicles: Yup.string().max(MAX_INFO_LENGTH, 'Max length is ' + MAX_INFO_LENGTH + '.').required('Vehicle information is required.')
        });
    }, []);

    const formDefaults = {
        vehicles: reservation.vehicle_license || ''
    };

    const methods = useForm<FormInputProps>({
        defaultValues: formDefaults,
        resolver: yupResolver(resolver),
        mode: 'all',
    });

    const {
        handleSubmit,
        formState: {
            isDirty,
            isSubmitting,
            isValid,
            dirtyFields,
            errors
        },
        trigger,
        setError,
        getFieldState,
        setValue,
        watch,
    } = methods;

    const [
        sendPatchRegistrationStep,
        {
            isLoading: patchStepIsLoading,
            isError: patchStepIsError,
            error: patchStepError,
            reset: resetPatchStepMutation
        },
    ] = usePatchRegistrationStepMutation();

    // const { sendEvent } = useAnalyticsContext()

    const onSubmit = handleSubmit(async ({
        vehicles
    }) => {
        if (stepCompleted) {
            callbackContinue();
            return;
        }

        const sfPayload = {
            reservationName: reservation.name,
            registrationStep: RegistrationPicklistItem.VEHICLE_REGISTRATION,
            vehicle_license: vehicles
        };

        await sendPatchRegistrationStep(sfPayload)
            .then((data: any) => {
                if (data.error) {
                    console.error(data.error.message);
                    return;
                }
                callbackContinue();
            })
            .catch((putError: any) => {
                console.error('error on update vehicles', putError);
            });

        //TODO
        // sendEvent(new GAEventGuest(
        //     sfPayload.type,
        //     sfPayload.is_minor,
        //     sfPayload.first_name,
        //     sfPayload.last_name,
        //     true,
        //     sfPayload.relation,
        //     reservation_name,
        // ))
    });

    return (
        <FormProvider name="Edit Vehicles Form" id={`Edit Vehicles Form: ${reservation.name}`} methods={methods} onSubmit={onSubmit}>
            <Stack spacing={2}>
                {patchStepIsError &&
                    <StandardRTKQError
                        error={patchStepError}
                        endpoint={cloud_patchRegistrationStep}
                        mutationCall
                    />
                }

                <RHFTextField
                    disabled={textInputLocked}
                    fullWidth
                    multiline
                    rows={3}
                    name="vehicles"
                    label='Vehicles'
                    onChange={(e) => {
                        setValue('vehicles', e.target.value.slice(0, MAX_INFO_LENGTH));
                        trigger('vehicles');
                    }}
                    />
                <ButtonGroup color="primary" variant="outlined" fullWidth size="large" disabled={isSubmitting}>
                    <Button
                        onClick={() => {
                            setValue('vehicles', 'TBD');
                            trigger('vehicles');
                            setTextInputLocked(true);
                        }}
                    >
                        Not Sure
                    </Button>
                    <Button
                        onClick={() => {
                            setValue('vehicles', 'N/A');
                            trigger('vehicles');
                            setTextInputLocked(true);
                        }}
                    >
                        No Vehicle
                    </Button>
                    <Button
                        onClick={() => {
                            setValue('vehicles', '');
                            trigger('vehicles');
                            setTextInputLocked(false);
                        }}
                    >
                        Clear
                    </Button>
                </ButtonGroup>
            </Stack>

            {/* Footer buttons */}
            <Stack direction='row' justifyContent='space-between' width='100%' mt={4}>
                <Button
                    disabled={isSubmitting}
                    onClick={callbackCancel}
                >
                    Back
                </Button>
                <LoadingButton
                    type="submit"
                    loading={patchStepIsLoading}
                    disabled={!isValid && !stepCompleted}
                    variant="contained"
                >
                    Continue
                </LoadingButton>
            </Stack>
        </FormProvider>
    );
}
