import { LoadingButton } from '@mui/lab';
import { Alert, AlertTitle, Button, List, ListItem, Stack, Typography } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { SplitRow } from '../../components/containers/SplitRow';
import { FullscreenModal } from '../../components/fullscreen-modal/FullscreenModal';
import Iconify from '../../components/iconify';
import { GuestGeneral } from '../../models/GuestGeneral';
import { ReservationGeneral } from '../../models/ReservationGeneral';
import { UserAccountGeneral } from '../../models/UserAccountGeneral';
import { checkAgeValid } from '../../utils/mrr/ageUtils';
import { RegistrationAdditionalGuests } from './RegistrationAdditionalGuests';
import { RegistrationChangeHostForm } from './RegistrationChangeHostForm';
import { RegistrationIdentityForm } from './RegistrationIdentityForm';
import { RegistrationMainGuestForm } from './RegistrationMainGuestForm';
import { RegistrationRentalAgreementForm } from './RegistrationRentalAgreementForm';
import { RegistrationStayReasonForm } from './RegistrationStayReasonForm';
import { RegistrationSummary } from './RegistrationSummary';
import { RegistrationUpsertGuestForm } from './RegistrationUpsertGuestForm';
import { RegistrationVehiclesForm } from './RegistrationVehiclesForm';
import { containerStyle, itemNameStyle, itemRowStyle } from './styles';
import { RegistrationWizardStep, WizardStepInfo } from './types';
import RegistrationServiceAnimalForm from './RegistrationServiceAnimalForm';

interface RegistrationWizardModalProps {
  allowRegistration: boolean;
  ageRequired: boolean;
  reservation: ReservationGeneral;
  mainGuest: GuestGeneral | null;
  guestModels: GuestGeneral[] | null;
  sessionUser: UserAccountGeneral;
  registrationSteps: WizardStepInfo[];
  stepIndex: number;
  setStepIndex: (value: number) => void;
  loading: boolean;
  error: Error | null;
  callbackMainGuestChange: VoidFunction;
  callbackRefreshReservation: (overrideBusy: boolean) => void;
  onClose: VoidFunction;
}

export function RegistrationWizardModal({
  allowRegistration,
  ageRequired,
  reservation,
  mainGuest,
  guestModels,
  sessionUser,
  registrationSteps,
  stepIndex,
  setStepIndex,
  loading,
  error,
  callbackMainGuestChange,
  callbackRefreshReservation,
  onClose,
}: RegistrationWizardModalProps) {
  const [ageRequirementsPassed, setAgeRequirementsPassed] = useState(true);
  const [activeGuest, setActiveGuest] = useState<GuestGeneral | null>(null);

  //NOTE: Once the Rental Agreement step is signed and completed, the user can no longer edit previous steps.
  const rentalAgreementStepCompleted = useMemo(() => {
    const raStep = registrationSteps.find((s) => s.key === RegistrationWizardStep.RentalAgreement);
    if (!raStep) {
      console.warn('rental agreement step not found');
      return false;
    }
    return raStep.completed;
  }, [registrationSteps]);

  const currentEnum = registrationSteps[stepIndex].key;

  const handleContinue = () => {
    if (registrationSteps[stepIndex].subStep) {
      // special logic for substeps, which jump around rather than using +/- 1
      switch (currentEnum) {
        case RegistrationWizardStep.SubChangeHost: {
          const mainGuestStepIndex = registrationSteps.findIndex(
            (s) => s.key === RegistrationWizardStep.MainGuest
          );
          if (mainGuestStepIndex === -1) {
            console.warn('MainGuest step not found');
            setStepIndex(0);
            return;
          }

          //NOTE: This assumes that MainGuest is _not_ the final step in the wizard.
          setStepIndex(mainGuestStepIndex + 1);
          return;
        }
        case RegistrationWizardStep.SubAddGuest:
        case RegistrationWizardStep.SubEditGuest: {
          const additionalGuestsStepIndex = registrationSteps.findIndex(
            (s) => s.key === RegistrationWizardStep.AdditionalGuests
          );
          if (additionalGuestsStepIndex === -1) {
            console.warn('AdditionalGuests step not found; from ' + currentEnum);
            setStepIndex(0);
            return;
          }

          // no advance, just back to all-guests
          setStepIndex(additionalGuestsStepIndex);
          return;
        }
        default:
          break;
      }
    }

    if (currentEnum === RegistrationWizardStep.Summary) {
      onClose();
      return;
    }

    if (stepIndex + 1 >= registrationSteps.length) {
      //NOTE: This is now very unlikely, due to the substeps (which get sorted to the end).
      //      It's essentially a failsafe, which is why it calls onClose().
      callbackRefreshReservation(false);
      onClose();
      return;
    }

    if (registrationSteps[stepIndex + 1].subStep) {
      //NOTE: This assumes that _all_ substeps have been sorted to the end of registrationSteps!
      callbackRefreshReservation(false);

      // no call to onClose(), we'll let the guard close us
      return;
    }

    // from the greeting page, we jump to first unfinished step, as a user convenience
    if (currentEnum === RegistrationWizardStep.Greeting) {
      //NOTE: Regarding rental-agreement already signed, here:
      //      Our rule for completing registration is all-steps-checked. However, an admin can interfere with
      //      those steps in SF. They can create gaps.
      //      Since you can't change your info _after_ the rental agreement has been signed, this means a step
      //      can be permanently inaccessible. In that case, registration can't be completed from the portal.
      //      That case never gets here. We block the modal with a 'Contact Guest Experience.' prompt.

      for (let i = stepIndex + 1; i < registrationSteps.length; i++) {
        if (!registrationSteps[i].completed) {
          setStepIndex(i);
          return;
        }
      }
    }

    setStepIndex(Math.min(registrationSteps.length - 1, stepIndex + 1));
  };

  const handleSubNavChangeHost = () => {
    if (rentalAgreementStepCompleted) {
      return;
    }

    //NOTE: 'Sub nav' is for certain step that don't nav with +/- 1. Instead, we jump to it, then jump back.
    //      It's marked as 'subStep', meaning it's a child of the another step, in this case Main Guest.
    if (currentEnum !== RegistrationWizardStep.MainGuest) {
      console.warn('Change Host only available on MainGuest step');
    }
    const changeHostStepIndex = registrationSteps.findIndex(
      (s) => s.key === RegistrationWizardStep.SubChangeHost
    );
    if (changeHostStepIndex === -1) {
      console.warn('ChangeHost step not found');
      setStepIndex(0);
      return;
    }
    setStepIndex(changeHostStepIndex);
  };

  const handleSubNavAddGuest = () => {
    if (rentalAgreementStepCompleted) {
      return;
    }
    if (currentEnum !== RegistrationWizardStep.AdditionalGuests) {
      console.warn('Add Guest only available on AdditionalGuests step');
    }
    const addGuestStepIndex = registrationSteps.findIndex(
      (s) => s.key === RegistrationWizardStep.SubAddGuest
    );
    if (addGuestStepIndex === -1) {
      console.warn('AddGuest step not found');
      setStepIndex(0);
      return;
    }
    setStepIndex(addGuestStepIndex);
  };

  const handleSubNavEditGuest = () => {
    if (rentalAgreementStepCompleted) {
      return;
    }
    if (currentEnum !== RegistrationWizardStep.AdditionalGuests) {
      console.warn('Edit Guest only available on AdditionalGuests step');
    }
    const editGuestStepIndex = registrationSteps.findIndex(
      (s) => s.key === RegistrationWizardStep.SubEditGuest
    );
    if (editGuestStepIndex === -1) {
      console.warn('EditGuest step not found');
      setStepIndex(0);
      return;
    }
    setStepIndex(editGuestStepIndex);
  };

  const handleNavBack = () => {
    if (rentalAgreementStepCompleted) {
      return;
    }
    if (stepIndex - 1 < 0) {
      // quitting out from the start of the wizard
      onClose();
      return;
    }

    // special logic on back form certain steps
    switch (currentEnum) {
      case RegistrationWizardStep.SubChangeHost: {
        const mainGuestStepIndex = registrationSteps.findIndex(
          (s) => s.key === RegistrationWizardStep.MainGuest
        );
        if (mainGuestStepIndex === -1) {
          console.warn('MainGuest step not found');
          setStepIndex(0);
          return;
        }
        setStepIndex(mainGuestStepIndex);
        return;
      }
      case RegistrationWizardStep.SubAddGuest:
      case RegistrationWizardStep.SubEditGuest: {
        const additionalGuestsStepIndex = registrationSteps.findIndex(
          (s) => s.key === RegistrationWizardStep.AdditionalGuests
        );
        if (additionalGuestsStepIndex === -1) {
          console.warn('additionalGuests step not found; form ' + currentEnum);
          setStepIndex(0);
          return;
        }
        setStepIndex(additionalGuestsStepIndex);
        return;
      }
      default:
        break;
    }

    setStepIndex(Math.max(0, stepIndex - 1));
  };

  // this enforces the all-guests-have-an-age rule
  useEffect(() => {
    if (ageRequired && currentEnum === RegistrationWizardStep.AdditionalGuests) {
      const allGuestsHaveAge = guestModels?.every(({ age }) => checkAgeValid(age));
      setAgeRequirementsPassed(allGuestsHaveAge === true);
      return;
    }
    if (!ageRequirementsPassed) {
      setAgeRequirementsPassed(true);
    }
  }, [ageRequired, ageRequirementsPassed, currentEnum, guestModels, stepIndex]);

  let showBackButton = true;
  if (
    currentEnum === RegistrationWizardStep.Greeting ||
    currentEnum === RegistrationWizardStep.Summary
  ) {
    showBackButton = false;
  }
  if (rentalAgreementStepCompleted) {
    showBackButton = false;
  }

  const enableContinueButton =
    allowRegistration && ageRequirementsPassed && !loading && error === null;

  return (
    <FullscreenModal
      showDialog
      onClose={onClose}
      titleText={registrationSteps[stepIndex].title}
      subtitleText={registrationSteps[stepIndex].instruction}
      boldSubtitle
      maxWidth={currentEnum === RegistrationWizardStep.RentalAgreement ? 'md' : 'sm'}
      fullscreenBreakpoint={currentEnum === RegistrationWizardStep.RentalAgreement ? 'md' : 'sm'}
    >
      <Stack spacing={2}>
        {!allowRegistration
          ? !loading && (
              <Alert severity="warning">
                You are not authorized to complete registration for this stay.
              </Alert>
            )
          : null}

        <>
          {currentEnum === RegistrationWizardStep.Greeting && (
            <div style={{ height: '400px' }}>
              <Alert severity="warning">
                <AlertTitle>Registration Required</AlertTitle>
                <span style={{ fontSize: '1rem' }}>
                  Guest registration is required to confirm your reservation. Failure to register
                  will result in the cancelation of your reservation.
                </span>
              </Alert>
              <br />
              <strong>Please complete the following items:</strong>
              <br />
              <div style={containerStyle}>
                {registrationSteps.map((s: WizardStepInfo, index) => {
                  if (
                    s.key === RegistrationWizardStep.Greeting ||
                    s.key === RegistrationWizardStep.Summary
                  ) {
                    return null;
                  }
                  if (s.subStep) {
                    return null;
                  }
                  return (
                    <div key={index} style={itemRowStyle}>
                      {s.completed ? (
                        <Iconify icon="mdi:checkbox-outline" />
                      ) : (
                        <Iconify icon="mdi:checkbox-blank-outline" />
                      )}
                      <div style={itemNameStyle}>{s.title}</div>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
          {currentEnum === RegistrationWizardStep.MainGuest && (
            <>
              <List
                dense
                disablePadding
                sx={{
                  listStyleType: 'disc', // Display bullet points
                  pl: 2, // Padding left for the list
                  '& .MuiListItem-root': {
                    display: 'list-item', // Treat ListItem as list-item for bullets
                    paddingLeft: 0, // Remove default padding
                    marginLeft: 0, // Adjust margin to close gap
                  },
                }}
              >
                <ListItem>
                  <Typography>
                    The host is the person checking in and responsible for all other guests.
                  </Typography>
                </ListItem>
                <ListItem>
                  <Typography>
                    If the host is a different person, please click change host.
                  </Typography>
                </ListItem>
              </List>
              <RegistrationMainGuestForm
                stepCompleted={registrationSteps[stepIndex].completed}
                ageRequired
                minAge={ageRequired ? reservation.minimum_age : 0}
                mainGuest={mainGuest}
                sessionUser={sessionUser}
                reservationName={reservation.name}
                callbackCancel={() => {
                  handleNavBack();
                }}
                callbackChangeHost={() => {
                  handleSubNavChangeHost();
                }}
                callbackContinue={() => {
                  handleContinue();
                }}
              />
            </>
          )}
          {currentEnum === RegistrationWizardStep.SubChangeHost && (
            <RegistrationChangeHostForm
              ageRequired
              minAge={ageRequired ? reservation.minimum_age : 0}
              mainGuest={mainGuest}
              reservationName={reservation.name}
              callbackMainGuestChange={callbackMainGuestChange}
              callbackCancel={() => {
                handleNavBack();
              }}
              callbackContinue={() => {
                handleContinue();
              }}
            />
          )}
          {currentEnum === RegistrationWizardStep.AdditionalGuests && (
            <RegistrationAdditionalGuests
              stepCompleted={registrationSteps[stepIndex].completed}
              mainGuest={mainGuest as GuestGeneral}
              ageRequired
              reservation={reservation}
              guestModels={guestModels}
              callbackCancel={() => {
                handleNavBack();
              }}
              callbackAddGuest={() => {
                handleSubNavAddGuest();
              }}
              callbackEditGuest={(guest: GuestGeneral) => {
                setActiveGuest(guest);
                handleSubNavEditGuest();
              }}
              callbackContinue={() => {
                handleContinue();
              }}
            />
          )}
          {currentEnum === RegistrationWizardStep.SubAddGuest && (
            <RegistrationUpsertGuestForm
              reservationName={reservation.name}
              guestToEdit={null}
              callbackCancel={() => {
                handleNavBack();
              }}
              callbackSubmit={() => {
                handleContinue();
              }}
            />
          )}
          {currentEnum === RegistrationWizardStep.SubEditGuest &&
            (activeGuest === null ? (
              <Alert severity="error">No guest selected to edit</Alert>
            ) : (
              <RegistrationUpsertGuestForm
                reservationName={reservation.name}
                guestToEdit={activeGuest}
                callbackCancel={() => {
                  handleNavBack();
                }}
                callbackSubmit={() => {
                  handleContinue();
                }}
              />
            ))}
          {currentEnum === RegistrationWizardStep.StayReason && (
            <RegistrationStayReasonForm
              stepCompleted={registrationSteps[stepIndex].completed}
              reservation={reservation}
              callbackCancel={() => {
                handleNavBack();
              }}
              callbackContinue={() => {
                handleContinue();
              }}
            />
          )}
          {currentEnum === RegistrationWizardStep.Vehicles && (
            <>
              <List
                dense
                disablePadding
                sx={{
                  listStyleType: 'disc', // Display bullet points
                  pl: 2, // Padding left for the list
                  '& .MuiListItem-root': {
                    display: 'list-item', // Treat ListItem as list-item for bullets
                    paddingLeft: 0, // Remove default padding
                    marginLeft: 0, // Adjust margin to close gap
                  },
                }}
              >
                <ListItem>
                  <Typography>If you do not know at this time, please click Not Sure.</Typography>
                </ListItem>
                <ListItem>
                  <Typography>If you will not have a vehicle, please click No Vehicle.</Typography>
                </ListItem>
              </List>
              <RegistrationVehiclesForm
                stepCompleted={registrationSteps[stepIndex].completed}
                reservation={reservation}
                callbackCancel={() => {
                  handleNavBack();
                }}
                callbackContinue={() => {
                  handleContinue();
                }}
              />
            </>
          )}
          {currentEnum === RegistrationWizardStep.ServiceAnimal && (
            <RegistrationServiceAnimalForm
              stepCompleted={registrationSteps[stepIndex].completed}
              reservation={reservation}
              handleContinue={handleContinue}
              handleNavBack={handleNavBack}
            />
          )}

          {currentEnum === RegistrationWizardStep.RentalAgreement && (
            <RegistrationRentalAgreementForm
              stepCompleted={registrationSteps[stepIndex].completed}
              stepFinal={registrationSteps[stepIndex].final}
              reservation={reservation}
              callbackCancel={() => {
                handleNavBack();
              }}
              callbackContinue={() => {
                handleContinue();
              }}
              enforceSessionIsMain
              mainGuest={mainGuest}
              sessionUser={sessionUser}
            />
          )}

          {currentEnum === RegistrationWizardStep.IdentityVerification && (
            <RegistrationIdentityForm
              stepCompleted={registrationSteps[stepIndex].completed}
              stepFinal={registrationSteps[stepIndex].final}
              reservation={reservation}
              callbackRefreshReservation={callbackRefreshReservation}
              callbackCancel={() => {
                handleNavBack();
              }}
              callbackContinue={() => {
                handleContinue();
              }}
              enforceSessionIsMain
              mainGuest={mainGuest}
              sessionUser={sessionUser}
            />
          )}

          {currentEnum === RegistrationWizardStep.Summary && (
            <RegistrationSummary
              reservation={reservation}
              callbackClose={() => {
                onClose();
              }}
            />
          )}
        </>

        {/* Footer */}
        {!registrationSteps[stepIndex].rendersFooter && (
          <SplitRow>
            {
              showBackButton ? (
                <Button variant="text" onClick={handleNavBack}>
                  Back
                </Button>
              ) : (
                <>&nbsp;</>
              ) // force the continue button to the right
            }
            <LoadingButton
              disabled={!enableContinueButton}
              variant="contained"
              loading={loading}
              onClick={handleContinue}
            >
              {currentEnum !== RegistrationWizardStep.Summary ? 'Continue' : 'Close'}
            </LoadingButton>
          </SplitRow>
        )}
      </Stack>
    </FullscreenModal>
  );
}
